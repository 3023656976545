<template>
  <div class="sally-search-box">
    <input id="search" type="text" :class="{'has-keyword': value.length > 0}"
           @input="$emit('input', $event.target.value)"
           autocomplete="off"
           :placeholder="placeholderValue"
           :value="value"/>
    <label for="search" :title="$t('icon.search')" />
  </div>
</template>

<script>
export default {
  name: 'SallySearchBox',
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
    placeholderValue: {
      type: String,
      default() {
        return this.$t('placeholder_search');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.sally-search-box {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  label {
    width: 16px;
    height: 20px;
    background-image: url('~@/assets/images/search.svg');
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 16px 16px;
    transition: all .2s ease-in-out;
    margin-right: 6px;
    &:hover, &:active, &:focus {
      background-image: url('~@/assets/images/search-green.svg');
    }
  }
  input[type=text] {
    width: 100%;
    height: 100%;
    transition: all .2s ease-in-out;
    font-size: $font_size_14;
    font-weight: $font_weight_400;
    line-height: 1.36;
    letter-spacing: -0.35px;
    color: #707070;
    &:hover + label, &:active + label, &:focus + label {
      background-image: url('~@/assets/images/search-green.svg');
    }
    &:focus {
      outline: none;
    }
    &.has-keyword {
      border-bottom: solid 1px #acacac;
      &:focus, &:hover, &:active {
        outline: none;
        border-bottom: 1px solid $primary_color;
      }
    }
    &.has-keyword + label {
      background-image: url('~@/assets/images/search-green.svg');
    }
  }
}
</style>
