<template>
<div class="user">
  <div class="title">{{ $t('user.title') }}</div>
  <div class="contents">
    <div class="tool-bar">
      <sally-search-box class="search-bar" v-model="keyword"/>
      <button @click="$modal.show('add-user-modal')">{{ $t('user.registration') }}</button>
    </div>
    <div class="list">
      <div class="header">
        <div class="username">{{ $t('user.username') }}</div>
        <div class="display-name">{{ $t('user.display_name') }}</div>
        <div class="email">{{ $t('user.email') }}</div>
        <div class="buttons" />
      </div>
      <div class="list-item" v-for="(user, index) in filteredUsers" :key="index">
        <div class="username">{{user.username}}</div>
        <div class="display-name">{{user.displayName}}</div>
        <div class="email">{{user.email}}</div>
        <div class="buttons">
          <div class="image delete" @click="showDeleteModal(user)"/>
        </div>
      </div>
    </div>
  </div>
  <add-user-modal @add="addUser"/>
  <delete-user-modal :username="selectedUsername" @delete="deleteUser"/>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import SallySearchBox from '@/components/Common/SallySearchBox.vue';
import AddUserModal from '@/components/Modal/AddUserModal.vue';
import DeleteUserModal from '@/components/Modal/DeleteUserModal.vue';

export default {
  name: 'User',
  components: {
    AddUserModal,
    DeleteUserModal,
    SallySearchBox,
  },
  data() {
    return {
      users: [],
      keyword: '',
      selectedUsername: '',
    };
  },
  computed: {
    filteredUsers() {
      const keyword = this.keyword.toUpperCase().trim();
      return this.users.filter(user => user.username.toUpperCase().includes(keyword)
        || user.displayName.toUpperCase().includes(keyword)
        || user.email.toUpperCase().includes(keyword));
    },
  },
  async created() {
    this.users = await this.getUsers();
  },
  methods: {
    ...mapActions(['getUsers']),
    showDeleteModal(user) {
      this.selectedUsername = user.username;
      this.$modal.show('delete-user-modal');
    },
    deleteUser() {
      this.users = this.users.filter(user => user.username !== this.selectedUsername);
      this.selectedUsername = '';
    },
    addUser(user) {
      this.users = [user, ...this.users];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.user {
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  .title {
    padding: 0 48px;
    display: flex;
    align-items: center;
    height: 75px;
    background-color: #f7f8fa;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: normal;
    color: #2a324b;
  }
  .contents {
    padding: 0 48px;
    .tool-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
      .search-bar {
        width: 200px;
      }
    }
    .list {
      .header, .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .username, .display-name, .email {
          padding: 0 10px;
          word-wrap: break-word;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .username {
          flex: 1;
          min-width: 50px;
        }
        .display-name {
          flex: 1.5;
          min-width: 90px;
        }
        .email {
          flex: 2;
          min-width: 100px;
        }
        .buttons {
          width: 30px;
        }
      }
      .header {
        min-height: 40px;
        background-color: rgba(218, 220, 224, 0.35);
        display: flex;
        flex-direction: row;
        align-items: center;
        .username, .display-name, .email, .buttons {
          font-weight: 600;
        }
      }
      .list-item {
        min-height: 50px;
        border-bottom: solid 1px #dadce0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .buttons {
          .image {
            cursor: pointer;
            background-repeat: no-repeat;
            &.delete {
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
              background-image: url('~@/assets/images/delete.svg');
              &:hover {
                background-image: url('~@/assets/images/delete-hover.svg');
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $max_width_size_for_mobile) {
    .title, .contents {
      padding: 0 24px;
    }
  }
}
</style>
