<template>
  <sally-confirmation-modal name="delete-user-modal"
    class="add-image-modal" height="179">
    <template v-slot:message>
      <div class="message">
        <img src="~@/assets/images/caution_red.svg" alt="caution"/>
        <span>{{ $t('delete_user', { name: username })}}</span>
      </div>
    </template>
    <template v-slot:buttons>
      <button class="secondary" @click="hide">{{ $t('cancel') }}</button>
      <button class="warning" @click="remove">{{ $t('delete') }}</button>
    </template>
  </sally-confirmation-modal>
</template>

<script>
import { mapActions } from 'vuex';
import SallyConfirmationModal from '@/components/Common/SallyConfirmationModal.vue';

export default {
  name: 'DeleteUserModal',
  props: {
    username: String,
    default() {
      return '사용자';
    },
  },
  components: {
    SallyConfirmationModal,
  },
  methods: {
    ...mapActions(['deleteUser']),
    async remove() {
      await this.deleteUser(this.username);
      this.hide();
      this.$emit('delete');
    },
    hide() {
      this.$modal.hide('delete-user-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.add-image-modal {
  .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    span {
      font-size: 16px;
      letter-spacing: normal;
      color: #242424;
    }
  }
}
</style>
