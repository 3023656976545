<template>
  <sally-modal name="add-user-modal" class="add-user-modal" height="600" width="500">
    <template v-slot:title>{{ $t('add_user') }}</template>
    <template v-slot:contents>
      <div class="scroll">
        <div class="contents">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <validation-provider
              :rules="`id|min:3|max:15|required|checkUser:${isValidUsername}`"
              v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>ID</label>
                <md-input v-model="username" @blur.native="check" placeholder="ID" />
                <span class="md-error sign-valid-message">*{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <validation-provider rules="required|name|min:1|max:20" v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>Name</label>
                <md-input v-model="displayName" placeholder="Name"></md-input>
                <span class="md-helper-text">{{$t("display_name")}}</span>
                <span class="md-error sign-valid-message">*{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <validation-provider mode="eager" rules="required|email"
                                 v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>Email</label>
                <md-input v-model="email" placeholder="Email" />
                <span class="md-error sign-valid-message">*{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <validation-provider name="password" class="password"
                                 rules="password" v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>Password</label>
                <md-input v-model="password" type="password" placeholder="Password"></md-input>
                <span class="md-helper-text">{{$t('password_hint_message')}}</span>
                <span class="md-error sign-valid-message">*{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <validation-provider rules="password_check:@password"
                                 v-slot="{errors}">
              <md-field :class="{'md-invalid': errors[0]}">
                <label>Confirm Password</label>
                <md-input v-model="confirmPassword" type="password"
                          @keyup.enter.native="register" placeholder="Confirm password"></md-input>
                <span class="md-error sign-valid-message">*{{errors[0]}}</span>
              </md-field>
            </validation-provider>
            <div class="button-group">
              <button class="secondary" @click="hide">{{ $t('cancel') }}</button>
              <button @click="add" :disabled="invalid">{{ $t('add') }}</button>
            </div>
          </validation-observer>
        </div>
      </div>
    </template>
  </sally-modal>
</template>

<script>
import { mapActions } from 'vuex';
import SallyModal from '@/components/Common/SallyModal.vue';

export default {
  name: 'AddUserModal',
  components: {
    SallyModal,
  },
  data() {
    return {
      username: '',
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isValidUsername: true,
    };
  },
  methods: {
    ...mapActions(['addUser', 'checkUsername']),
    init() {
      this.username = '';
      this.displayName = '';
      this.email = '';
      this.password = '';
      this.confirmPassword = '';
    },
    hide() {
      this.init();
      this.$modal.hide('add-user-modal');
    },
    async add() {
      const user = await this.addUser({
        username: this.username,
        displayName: this.displayName,
        email: this.email,
        password: this.password,
      });
      this.hide();
      this.$emit('add', user);
    },
    async check() {
      if (this.username) {
        this.isValidUsername = !await this.checkUsername(this.username);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-user-modal {
  .scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    .contents {
      width: 100%;
      height: 100%;
      display: block;
      padding: 18px 30px;
      .button-group {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0 32px;
        height: 68px;
        border-top: solid 1px #dadce0;
      }
    }
  }
}
</style>
